<template>
  <a-drawer width="60%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form"  :rules="rules">

      <a-form-model-item :label="$t('明星俱乐部.俱乐部名字')" prop="clubName" >
        <a-input v-model="form.clubName" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('明星俱乐部.俱乐部名字')" />
      </a-form-model-item>
      <a-form-item :label="this.$t('通用.文本.多语言配置')" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" class="margin-bottom-10">
        <a-table :data-source="form.localeList" :columns="localeColumns" rowKey="id" :pagination="false" bordered>
          <span slot="locale" slot-scope="text, record">
           <custom-dict-tag :options="customDict.LocaleEnum" :value="record.locale"/>
          </span>
          <div slot="name" slot-scope="text, record" >
            <a-input v-model="record.name" :maxLength="16" :placeholder="$t('通用.输入.请输入')+$t('明星俱乐部.俱乐部名字')" @blur.native.capture="validatorLocaleName"   />
          </div>
          <div slot="action" slot-scope="text, record">
            <a-button type="primary" @click="getTranslation(record)">
              {{$t('通用.按钮.翻译')}}
            </a-button>
          </div>
        </a-table>
        <span style="color: red;">{{ errorMessage }}</span>
      </a-form-item>
      <a-form-model-item :label="$t('商品.选择明星')" prop="starId">
        <div @click="openStarSelect" style="width: 100px">
          <a-button type="dashed" @click="openStarSelect" danger><span
            style="color: dodgerblue"> {{$t('商品.选择明星')}}</span>
          </a-button>
        </div>
      </a-form-model-item>
      <a-row>
        <a-col :span="4"></a-col>
        <a-col :span="12">
          <div v-if="form.starId">
            {{ form.starDetail.starName }}
            <img v-if="form.starDetail.picture" :src="form.starDetail.picture" style="width:60px;height:60px;" alt="none"/>
          </div>
        </a-col>
      </a-row>
      <a-form-model-item :label="$t('通用.文本.选择商品')" v-if="this.form.starId" >
        <div @click="openProdSelect" style="width: 100px">
          <a-button type="dashed" @click="openProdSelect" danger>
            <span style="color: dodgerblue"> {{$t('通用.文本.选择商品')}}</span>
          </a-button>
        </div>
      </a-form-model-item>
      <!-- 数据展示 -->
      <a-table v-if="this.form.starId" :loading="loading"
       :scroll="{ x: '130%' }"
       :size="tableSize"
        rowKey="id"
        :columns="goodsColumns"
        :data-source="form.goodsList"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <template slot="picture" slot-scope="text, record">
          <div>
            <img v-if="record.picture" :src="record.picture"  style="width:60px;height:62px;" @click="handleRecordPreview(record.picture)" alt="none"/>
          </div>
        </template>
        <span slot="categoryId" slot-scope="text, record">
            {{getCategoryNameById(record.categoryId)}}
          </span>
        <span slot="brandId" slot-scope="text, record">
             {{getBrandNameById(record.brandId)}}
          </span>
        <span slot="status" slot-scope="text, record">
           <custom-dict-tag :options="customDict.GoodsStatusEnum" :value="record.status"/>
          </span>
        <span slot="operation" slot-scope="text, record">
          <a @click="handleGoodsDelete(record.id)">
            <a-icon type="delete" />{{$t('通用.按钮.删除')}}
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination v-if="form.starId"
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="pageNum"
        :total="form.goodsNum"
        :page-size="pageSize"
        :showTotal="total => getTotal(form.goodsNum)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{$t('通用.按钮.保存')}}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{$t('通用.按钮.取消')}}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
    <star-select-form ref="StarSelectForm" @select="indexStarModalSelect"></star-select-form>
    <checkbox-goods-select-form ref="CheckboxGoodsSelectForm" @goodsSelect="indexGoodsModalSelect"></checkbox-goods-select-form>
  </a-drawer>
</template>

<script>
import { getClub, addClub, updateClub } from '@/api/star/club'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";
import { tableMixin } from '@/store/table-mixin'
import {translation} from "@/api/tool/common";
import StarSelectForm from "@/views/star/star/modules/SelectForm";
import CheckboxGoodsSelectForm from "@/views/goods/goods/modules/CheckboxSelectForm";
import {listCategory} from "@/api/goods/category";
import {allBrand} from "@/api/goods/brand";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    StarSelectForm,
    CheckboxGoodsSelectForm,
    CustomDictTag,
  },
  mixins: [tableMixin],
  data () {
    return {
      errorMessage:undefined,
      localeColumns: [
        {
          title: this.$t('通用.文本.语言'),
          dataIndex: 'locale',
          key: 'locale',
          scopedSlots: { customRender: 'locale' }
        }, {
          title: this.$t('明星俱乐部.俱乐部名字'),
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          key: 'name'
        }, {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'action',
          key: 'action',
          slots: { title: 'addbtn', customRender: 'name' },
          scopedSlots: { customRender: 'action' }
        }
      ],

      categoryList: [{id: "0", categoryName: ''}],
      cateOptions: [],
      brandList: [],

      pageSizeOptions: ['10', '20', '50', '100'],
      selectedRowKeys: [],
      selectedRows: [],
      pageNum: 1,
      pageSize: 10,
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: false,
      goodsColumns: [
        {
          title: this.$t('商品.商品编号'),
          dataIndex: 'goodsCode',
          width: 100,
          fixed: 'left',
        },
        {
          title: this.$t('商品.商品名称'),
          dataIndex: 'goodsName',
          width: 100,
          fixed: 'left',
        },
        /*{
          title: this.$t('商品.包装单位'),
          width: 60,
          dataIndex: 'unit',
        },*/
        {
          title: this.$t('商品.商品主图'),
          dataIndex: 'picture',
          width: 60,
          scopedSlots: {customRender: 'picture'},
        },
        {
          title: this.$t('商品.商品分类'),
          dataIndex: 'categoryId',
          width: 60,
          scopedSlots: {customRender: 'categoryId'},
        },
        {
          title: this.$t('商品.品牌'),
          dataIndex: 'brandId',
          width: 50,
          scopedSlots: {customRender: 'brandId'},
        },
        {
          title: this.$t('商品.售卖开始时间'),
          width: 150,
          dataIndex: 'saleStartTime',
        },
        {
          title: this.$t('商品.售卖结束时间'),
          width: 150,
          dataIndex: 'saleEndTime',
        },
        {
          title: this.$t('商品.市场价'),
          width: 50,
          dataIndex: 'marketPrice',
        },
        {
          title: this.$t('商品.商品价'),
          width: 50,
          dataIndex: 'price',
        },
        {
          title: this.$t('商品.商品状态'),
          dataIndex: 'status',
          width: 60,
          scopedSlots: {customRender: 'status'},
        },
        {
          title: this.$t('商品.创建时间'),
          dataIndex: 'createTime',
          width: 150,
          scopedSlots: {customRender: 'createTime'},
        },
        {
          title: this.$t('通用.文本.操作'),
          dataIndex: 'operation',
          fixed: 'right',
          width: 100,
          scopedSlots: {customRender: 'operation'},
        }
      ],
      // 表单参数
      form: {
        id: null,
        localeList: [],
        starId: null,
        starDetail: null,
        goodsNum: 0,
        clubName: null,
        goodsList: [],
        createTime: null,
        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        starId: [
          { required: true, message: this.$t('明星俱乐部.明星表')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ],
        clubName: [
          { required: true, message: this.$t('明星俱乐部.俱乐部名字')+this.$t('通用.文本.不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    this.getBrandList();
    this.getCategoryList();
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    openStarSelect () {
      this.$refs.StarSelectForm.openStar(this.form.starId);
    },
    indexStarModalSelect(record) {
      console.log('明星选择了',record)
      this.form.starId = record.id;
      this.form.starDetail = record
    },
    handleRecordPreview(url) {
      this.previewUrl = url
      this.previewImageVisible = true
    },
    indexGoodsModalSelect(records) {
      console.log('商品选择了',records)
      this.form.goodsList = records
      //this.form.starId = record.id;
      //this.form.starDetail = record
    },
    openProdSelect () {
      this.$refs.CheckboxGoodsSelectForm.openGoods(this.form.starId, this.form.goodsList, undefined);
    },

    getTotal(total) {
      return this.$t('通用.文本.共')+total+this.$t('通用.文本.条')
    },
    onShowSizeChange (current, pageSize) {
      this.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.pageNum = current
      this.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    /*text 要翻译的字段*/
    getTranslation(record) {
      if (!this.form.clubName) {
        return
      }
      let data = {"text": this.form.clubName, "locale": record.locale};
      translation(data).then(response => {
        record.name = response.data
      })
    },
    validatorLocaleName() {
      let errorMessage = "";
      var localeList = this.form.localeList;
      let localeEnum = this.customDict.LocaleEnum;
      localeList.forEach(e=>{
        if (!e.name) {
          let localeName = localeEnum.find(locale=>locale.type===e.locale).name;
          errorMessage += localeName + this.$t('通用.文本.不能为空')+';';
        }
      })
      if (errorMessage) {
        this.errorMessage = errorMessage;
        return false;
      }
      this.errorMessage='';
      return true;
    },
    initLocale() {
      //初始化多语言
      let localeList = [];
      let i = 1 ;
      this.customDict.LocaleEnum.forEach(e=>{
        localeList.push({"id":i ,"locale":e.type,'name':null })
        i++;
      })
      this.form.localeList = localeList;
    },
    /*页面初始化时获取分类类别*/
    getCategoryList() {
      listCategory().then(response => {
        this.categoryList = response.data;
      })
    },

    getCategoryNameById(id) {
      if (!id) {
        return "";
      }
      let cateInfo = this.categoryList.find(e => e.id === id);
      if (cateInfo) {
        return cateInfo.categoryName
      } else {
        return "";
      }
    },

    getBrandList() {
      allBrand().then(response => {
        this.brandList = response.data
      })
    },
    getBrandNameById(id) {
      if (!id) {
        return "";
      }
      let brandInfo = this.brandList.find(e => e.id === id);
      if (brandInfo) {
        return brandInfo.name
      } else {
        return "";
      }
    },
    handleGoodsDelete(key) {
      const dataSource = [...this.form.goodsList];
      this.form.goodsList = dataSource.filter(item => item.id !== key);
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        localeList: [],
        goodsList: [],
        goodsNum: 0,
        starId: null,
        starDetail: null,
        clubName: null,
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.initLocale()
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getClub({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        let localeValid = this.validatorLocaleName();
        if (!localeValid) {
          return false;
        }
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateClub(this.form).then(response => {
              this.$message.success(
                this.$t('通用.文本.修改成功'),
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addClub(this.form).then(response => {
              this.$message.success(
                  this.$t('通用.文本.新增成功'),
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
